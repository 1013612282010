<template>
  <v-btn
    @click="exportXlsx"
    color="green darken-3"
    dark
    :fab="!$vuetify.breakpoint.mdAndUp"
    :small="!$vuetify.breakpoint.mdAndUp"
    ><v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-file-excel</v-icon>
    <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("export.export") }}</span>
  </v-btn>
</template>

<script>
import XLSX from "xlsx";
import { mapState } from "vuex";
import { formatAlert, formatAlerts } from "../../services/alert";
import { getKeyLabel } from "@/services/rule.js";

export default {
  computed: {
    defaultColumns() {
      return [
        {
          label: this.$t("export.date"),
          field: "date",
        },
        {
          label: this.$t("export.value"),
          field: "triggered_value",
        },
        {
          label: this.$t("export.financialRisk"),
          field: "triggered_financial_risk",
        },
      ];
    },

    detailColumns() {
      return [
        {
          label: this.$t("export.origin"),
          field: "key",
        },
        {
          label: this.$t("export.value"),
          field: "triggered_value",
        },
        {
          label: this.$t("export.financialRisk"),
          field: "triggered_financial_risk",
        },
        {
          label: this.$t("export.duration"),
          field: "triggered_duration",
        },
      ];
    },

    ...mapState({
      alert: (state) =>
        formatAlert(state.alerts.alertDetail, {
          queries: state.rules.rulesNames,
        }),
      similarAlerts: (state) =>
        formatAlerts(state.alerts.similarAlerts, {
          queries: state.rules.rulesNames,
        }),
    }),
  },
  methods: {
    exportXlsx() {
      const alert = this.alert;

      const detail = [
        alert.key,
        alert.formatted.triggered_value,
        alert.formatted.triggered_financial_risk,
        alert.triggered_duration + " j",
      ];

      const previous = alert.previous.reduce((acc, val, i) => {
        if (i == 0) {
          acc.push({
            date: alert.formatted.date,
            value: alert.formatted.value,
            financial_risk: alert.formatted.financial_risk,
          });
        }

        acc.push({
          date: val.formatted.date,
          value: val.formatted.value,
          financial_risk: val.formatted.financial_risk,
        });

        return acc;
      }, []);

      const similars = this.similarAlerts.reduce((acc, val) => {
        acc.push({
          date: val.formatted.date,
          value: val.formatted.value,
          financial_risk: val.formatted.financial_risk,
        });

        return acc;
      }, []);

      // Retrieve default headers
      const defaultHeaders = this.defaultColumns.map((c) => {
        return getKeyLabel(c.field, { rule: alert.rule }) || c.label;
      });
      // Retrieve detail headers
      const detailHeaders = this.detailColumns.map((c) => {
        return getKeyLabel(c.field, { rule: alert.rule }) || c.label;
      });

      // Retrieve default column widths
      const defaultColWidth = defaultHeaders.map((str) => {
        return {
          width: str.length + 8,
        };
      });
      // Retrieve detail column widths
      const detailColWidth = detailHeaders.map((str) => {
        return {
          width: str.length + 8,
        };
      });

      // Prepare sheets objects
      const detailSheet = {
        name: "Détail",
        widths: detailColWidth,
        data: [],
      };
      const cumulSheet = {
        name: "Liste des événements",
        widths: defaultColWidth,
        data: [],
      };
      const simSheet = {
        name: "Hors période d'analyse",
        widths: defaultColWidth,
        data: [],
      };

      // Create book
      const wb = XLSX.utils.book_new();

      // Build detail sheet
      const ws = XLSX.utils.aoa_to_sheet([detailHeaders, detail]);
      ws["!cols"] = detailSheet.widths;
      XLSX.utils.book_append_sheet(wb, ws, detailSheet.name);

      // Build cumul sheet
      if (previous.length > 0) {
        const wsCumul = XLSX.utils.aoa_to_sheet([defaultHeaders]);
        XLSX.utils.sheet_add_json(wsCumul, previous, {
          skipHeader: true,
          origin: -1,
        });
        wsCumul["!cols"] = cumulSheet.widths;
        XLSX.utils.book_append_sheet(wb, wsCumul, cumulSheet.name);
      }

      // Build similars sheet
      if (similars.length > 0) {
        const wsSim = XLSX.utils.aoa_to_sheet([defaultHeaders]);
        XLSX.utils.sheet_add_json(wsSim, similars, {
          skipHeader: true,
          origin: -1,
        });
        wsSim["!cols"] = simSheet.widths;
        XLSX.utils.book_append_sheet(wb, wsSim, simSheet.name);
      }

      let filename =
        ["export_Alertel", alert.codepan, alert.date].join("_") + ".xlsx";

      XLSX.writeFile(wb, filename);
    },
  },
};
</script>
